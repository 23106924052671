<template>
    <div class="login-box">
        <!-- /.login-logo -->
        <form @submit="submitLogin" method="post">
            <div class="login-logo mb-5">
                <a href="#"><img height="64" src="@/assets/img/logo-white.png"></a>
                <br>
                <h5 style="color: white;">APP KHUSUS TENTOR</h5>
            </div>
            <div class="card mb-0">
                <div class="card-body login-card-body">
                    <div class="input-group mb-3 mt-5">
                        <input type="text" required="required" class="form-control" placeholder="Email" v-model="form.username">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" required="required" class="form-control" placeholder="Password" v-model="form.password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="social-auth-links text-center mb-3">
                        <button type="submit" class="btn btn-block btn-primary btn-lg" :disabled="loading">
                            <span v-if="loading" style="margin-bottom: 2px;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading?" Login...":"Login Sekarang" }} <i class="fa fa-sign-in-alt"></i>
                        </button>
                    </div>
                    <div class="row">
                        <div class="col-6"><router-link to="/register">Daftar</router-link></div>
                        <div class="col-6 text-right"><router-link to="/forgot-password">Lupa Password</router-link></div>
                    </div>
                    <div class="text-center mt-5 mb-5">
                        <p>All Right Reserved</p>
                        <p>TARUNA CENDEKIA © 2021</p>
                    </div>
                </div>
                <!-- /.login-card-body -->
            </div>
        </form>
    </div>
    <!-- /.login-box -->
</template>
<script>
import { auth } from '@/libs/hxcore';
import $ from 'jquery';
import '@/assets/login.css';
export default {
    name: 'Login',
    data() {
        return {
            errors: [],
            loading: false,
            failed: false,
            failedMsg: '',
            form: {
                username: '',
                password: ''
            }
        }
    },
    created: function() {
        $('body').addClass('hold-transition login-page');
        $('body').removeAttr('style');
    },
    methods: {
        submitLogin: function(e) {
            if (this.form.username && this.form.password) {
                var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                this.loading = true;
                this.failed = false;
                //this.$router.push('/');
                fetch(window.$apiUrl + '/login', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: data
                    })
                    .then(res => {
                        this.loading = false;
                        if (res.status === 201) {

                        } else if (res.status === 400) {
                            //let errorResponse = await res.json();
                            //this.errors.push(errorResponse.error);
                        }
                        return res.json();
                    })
                    .then(js => {
                        if (js.status) {
                            auth.login(js);
                            this.$router.push('/');
                        } else {
                            this.failed = true;
                            this.failedMsg = js.message;
                        }
                        console.log(js)
                    });
            }


            e.preventDefault();
        },
        loginWithGoogle() {
            this.$gAuth
                .signIn()
                .then(GoogleUser => {
                    // on success do something
                    console.log('GoogleUser', GoogleUser)
                    var userInfo = {
                        loginType: 'google',
                        google: GoogleUser
                    }
                    this.$store.commit('setLoginUser', userInfo)
                    router.push('/home')
                })
                .catch(error => {
                    console.log('error', error)
                })
        }

    }
}
$(function() {
    $('[data-toggle="password"]').each(function() {
        var input = $(this);
        var eye_btn = $(this).parent().find('.input-group-text');
        eye_btn.css('cursor', 'pointer').addClass('input-password-hide');
        eye_btn.on('click', function() {
            if (eye_btn.hasClass('input-password-hide')) {
                eye_btn.removeClass('input-password-hide').addClass('input-password-show');
                eye_btn.find('.fa').removeClass('fa-eye').addClass('fa-eye-slash')
                input.attr('type', 'text');
            } else {
                eye_btn.removeClass('input-password-show').addClass('input-password-hide');
                eye_btn.find('.fa').removeClass('fa-eye-slash').addClass('fa-eye')
                input.attr('type', 'password');
            }
        });
    });

    setInterval(function() {
        $('[data-slide="next"]').click();
    }, 5000);
});
</script>
<style lang="scss">
.login-page,
.register-page {
    -ms-flex-align: center;
    align-items: center;
    background: linear-gradient(90deg, #6190E8 0%, #A7BFE8 100%);
@media (max-width: 576px){
    .login-box, .register-box {
    margin-top: 20px;
    width: 360px; 
}
}

    .login-box {
        height: 100vh;
        position: relative;

        .form-control {
            height: calc(2rem + 2px);
            border:0;
        }

        .card {

            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
        }

        .login-card-body,
        .register-card-body {
            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
            background-color: #F1F1FA;
            .input-group .input-group-text
            {
                background-color:#fff;
                border:0;
            }
        }

        form {
            bottom: 0;
            position: absolute;
            width: 100%;
        }
    }
}
</style>
